<template>
  <div class="d-flex flex-nowrap">
    <document-ref-icon
      :visible="showIcon"
      :icon-name="iconName"
      :mixed="mixed"
      :x-small="_iconProps.xsmall"
      :small="_iconProps.small"
      :normal="_iconProps.normal"
      :large="_iconProps.large"
      :x-large="_iconProps.xlarge"
    />
    <div class="d-flex flex-column">
      <span
        :class="[color ? `${color}--text` : '']"
        class="text-truncate max-name-width"
      >{{name}}</span>
      <slot name="doc-type" />
    </div>
  </div>
</template>

<script>
import ClientCache from '@/mixins/client-cache'

export default {
  inheritAttrs: false,
  components: {
    DocumentRefIcon: () => import('@/components/document-ref-icon')
  },
  computed: {
    name () {
      return this.id ? this.cache?.doc?.name || this.cache?.doc?.number || this.cache?.doc?.id : this.$t('t.None')
    },
    _iconProps () {
      const _ = Object.assign({
        xsmall: false,
        small: false,
        normal: false,
        large: false,
        xlarge: false
      }, this.iconProps)

      return _
    }
  },
  mixins: [ClientCache],
  props: {
    id: [String, Number, Array],
    iconName: String,
    cacheType: Number,
    showIcon: Boolean,
    mixed: Boolean,
    color: String
  }
}
</script>

<style lang="stylus" scoped>
@css {
  .max-name-width {
    max-width: min(45vw, 300px);
  }
}
</style>
